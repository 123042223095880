// src/components/common/Footer.jsx

import * as React from 'react';
import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


function Footer() {

  const date = new Date().getFullYear()
  const FooterContact = React.useRef(null);

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from(FooterContact.current, {
      scrollTrigger: {
        trigger: FooterContact.current,
        start: 'center bottom',
        end: 'top top',
        toggleActions: 'play none none none',
      },
      opacity: 0,
      y: 60,
      duration: 1,
    });

    return () => {
      gsap.killTweensOf(FooterContact);
      ScrollTrigger.refresh();
    }

  }, []);


  return (

      <footer id={'footer'} className={'w-screen min-h-screen py-24 sm:py-16 lg:py-44 bg-dark md:pl-10'}>
        <div className={'container mx-auto flex flex-col justify-center'}>

          <div ref={FooterContact} className={'flex flex-row flex-wrap items-center justify-between'}>
            <div className={'max-sm:mb-12 basis-60 lg:basis-2/3'}>
              <h2 className={'text-white font-light leading-normal text-[1.6rem] sm:text-4xl'}>Let's work together!</h2>
            </div>
            <div className={'basis-full sm:basis-1/2 lg:basis-1/4 lg:pl-3'}>
              <Link to={'/contact/#contact'} className={'text-base text-gray hover:text-white'} activeClassName={'text-white'}
                    aria-label={'Start a project'}>Start a project</Link>
            </div>
          </div>


          <div className={'flex flex-col lg:flex-row flex-wrap justify-between mt-20 lg:mt-28  text-xs sm:text-sm'}>

            <div className={'basis-30 lg:basis-2/5 order-last lg:order-first leading-loose'}>
              <p className={'text-gray font-light'}>Copyright © {date} Ziolkowski Media. NYC.<br/>All rights reserved.</p>
              <ul className={'flex text-gray mt-14'}>
                <li>
                  <a href={'https://www.linkedin.com/in/peter-lucas-z/'} target={'_blank'} rel={'noreferrer noopener'}
                     className={'mr-10 sm:mr-8 hover:text-white'}
                     aria-label={'LinkedIn'}>LinkedIn</a>
                </li>
                <li>
                  <a href={'https://useme.com/en/roles/contractor/ziolkowski-media,299576/'} target={'_blank'}
                     rel={'noreferrer noopener'} className={'mr-10 sm:mr-8 hover:text-white'}
                     aria-label={'Useme'}>Useme</a>
                </li>
                <li>
                  <Link to={'/privacy-policy/'} className={'hover:text-white'} activeClassName={'text-white'}
                        aria-label={'Privacy Policy'}>Privacy Policy</Link>
                </li>
              </ul>
            </div>

            <div className={'basis-36 lg:basis-1/4'}>
              <h3 className={'text-dark-gray text-sm sm:text-base font-light'}>Get in touch</h3>
              <ul className={'leading-loose text-white font-light mt-6'}>
                <li>
                  <a href={'mailto:hello@ziolkowski.media'} className={'mr-8 sm:mr-8 hover:text-white'}
                     aria-label={'hello@ziolkowski.media'}>hello@ziolkowski.media</a>
                </li>
              </ul>
            </div>

            <div className={'basis-36 mb-16 lg:basis-1/4 lg:pl-3'}>
              <h3 className={'text-dark-gray text-sm sm:text-base font-light'}>Learn more</h3>
              <nav>
                <ul className={'text-gray font-light leading-loose text-xs mt-6'}>
                  <li>
                    <Link to={'/'} className={'hover:text-white'} activeClassName={'text-white'}
                          aria-label={'Home'}>Home</Link>
                  </li>
                  <li className={'mt-5 sm:mt-3'}>
                    <Link to={'/about/'} className={'hover:text-white'} activeClassName={'text-white'}
                          aria-label={'About'}>About</Link>
                  </li>
                  <li className={'mt-5 sm:mt-3'}>
                    <Link to={'/contact/'} className={'hover:text-white'} activeClassName={'text-white'}
                          aria-label={'Contact'}>Contact</Link>
                  </li>
                </ul>
              </nav>
            </div>

          </div>

        </div>
      </footer>

  )
}

export default Footer