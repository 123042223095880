module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ziolkowski Media | Creating World-Class Digital Media for Modern Enterprise","short_name":"Ziolkowski","description":"NYC-based creative studio and digital media powerhouse, specializing in immersive web experiences, impactful marketing campaigns, and content creation.","start_url":"/","lang":"en","background_color":"#000","theme_color":"#000","display":"standalone","icon":"src/images/maskable_icon.png","cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
