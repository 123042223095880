import './src/styles/global.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/300.css'


import React from 'react';
import Layout from './src/components/Layout';


// Main Layout Wrapper
export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>;
};

// Trigger gatsby-plugin-offline update notification inside Layout.js
export const onServiceWorkerUpdateReady = () => {
  const event = new Event('serviceWorkerUpdateReady');
  if (typeof window !== 'undefined') {
    window.dispatchEvent(event);
  }
};

// PostHog pageview event fix
export const onRouteUpdate = () => {
  if (typeof window !== 'undefined' && window.posthog) {
    window.posthog.capture('$pageview');
  }
}
