// src/components/common/Header.jsx

import * as React from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Logo from '../../images/assets/logo.svg';
import { CgMenu, CgClose } from 'react-icons/cg';


function Header() {

  const HeaderRef = React.useRef(null);
  const MenuRef = React.useRef(null);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  // Header animations
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (HeaderRef.current) {
      gsap.from(HeaderRef.current, {
        delay: 0.4,
        duration: 0.4,
        y: 60,
        opacity: 0,
        ease: 'power1.out',
      });
    }

    return () => {
      gsap.killTweensOf(HeaderRef);
      ScrollTrigger.refresh();
    }

  }, [location.pathname]);


  React.useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.classList.add('overflow-y-hidden')
    } else if (!isMenuOpen) {
      document.documentElement.classList.remove('overflow-y-hidden')
    }
    return () => {
      document.documentElement.classList.remove('overflow-y-hidden');
    };
  }, [isMenuOpen]);

  // Class names based on state
  const headerClassNames = `w-screen z-50 fixed py-7 ${
    isMenuOpen
      ? 'h-full bg-dark/70 backdrop-filter backdrop-blur-2xl backdrop-saturate-150'
      : 'mix-blend-difference'
    }`;

  const menuClassNames = `text-right font-light text-white sm:block ${
    isMenuOpen ? 'mt-7 text-[1.1em] font-normal' : 'hidden text-xs'
  }`;


  return (
      <header ref={HeaderRef} className={headerClassNames}>
        <div className={'flex justify-between  container mx-auto'}>
          <div id={'logo'}>
            <Link onClick={() => setIsMenuOpen(false)} to={'/'} aria-label={'Home'}>
              <Logo />
            </Link>
          </div>
          <div id={'nav-menu'} className={'grid place-items-end sm:place-items-center'}>
            <button
                className={'sm:hidden block text-white text-4xl'}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            >
              {isMenuOpen ? <CgClose /> : <CgMenu />}
            </button>
            <nav ref={MenuRef} className={menuClassNames}>
              <ul className={`sm:flex`}>
                <li>
                  <Link onClick={() => setIsMenuOpen(false)} to={'/'} className={'sm:mr-16 hover:text-white'} activeClassName={'text-white'} aria-label={'Home'}>
                    Home
                  </Link>
                </li>
                <li className={'max-sm:my-7'}>
                  <Link onClick={() => setIsMenuOpen(false)} to={'/about'} className={'sm:mr-16 hover:text-white'} activeClassName={'text-white'} aria-label={'About'}>
                    About
                  </Link>
                </li>
                <li>
                  <Link onClick={() => setIsMenuOpen(false)} to={'/contact'} className={'hover:text-white'} activeClassName={'text-white'} aria-label={'Contact'}>
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
  );
}

export default Header;