// src/components/common/UpdateHelper.jsx

import React from 'react';
import { gsap } from 'gsap';

import Download from '../../images/assets/icons/download.svg';
import {ScrollTrigger} from "gsap/ScrollTrigger";


const UpdateHelper = ({ isOpen }) => {

  // Update notification entrance animation
  const UpdateHelperRef = React.useRef(null);
  React.useEffect(() => {
    // Only run the animation if the modal is open and the element is rendered
    if (isOpen && UpdateHelperRef.current) {
      gsap.from(UpdateHelperRef.current, {
        delay: 1,
        duration: 0.3,
        opacity: 0,
      });
    }

    return () => {
      gsap.killTweensOf(UpdateHelperRef);
      ScrollTrigger.refresh();
    }
  }, [isOpen]);

  const ReloadPage = () => {
    window.location.reload(true);
  };

  if (!isOpen) return null;

  return (
      <div ref={UpdateHelperRef} className={'fixed bottom-5 left-5 right-5 h-15 w-fit z-50 p-5 border border-0.5 border-gray-lighter/10 bg-dark/60 backdrop-filter backdrop-blur-2xl backdrop-saturate-150 backdrop-brightness-120 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]'}>
        <div className={'flex flex-row justify-between items-center gap-y-4 text-xs'}>
          <Download className={'w-[14px] min-w-[14px] mr-4 opacity-50'}/>
          <div className={'mr-8 sm:mr-12'}>
            <h2 className={'text-white font-medium leading-loose mb-1'}>Update Available!</h2>
            <p className={'text-gray-lighter leading-relaxed'}>This application has been updated. Reload to display the latest version?</p>
          </div>
          <button
              className={'text-white text-xs px-7 py-1.5 mr-2 border-[0.5px] rounded-md border-white hover:shadow-[4px_4px_0px_white] transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-md active:translate-x-[0px] active:translate-y-[0px] active:rounded-md active:shadow-none'}
              aria-label={'Reload'} onClick={ReloadPage}>
            Reload
          </button>
        </div>
      </div>
  );
};

export default UpdateHelper;
