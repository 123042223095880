// src/components/common/ScrollHelper.jsx

import * as React from 'react';
import { useLocation } from '@reach/router';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { FiArrowDownCircle } from 'react-icons/fi';

function ScrollHelper() {

  const ScrollHelperRef = React.useRef(null);
  const ScrollTooltipRef = React.useRef(null);
  const ScrollIconRef = React.useRef(null);
  const [isScrollToTop, setIsScrollToTop] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ScrollIcon = ScrollIconRef
    const ScrollTooltip = ScrollTooltipRef

    gsap.set(ScrollHelperRef.current, { display: 'block' }); // Unhide the Scroll Helper

    gsap.from(ScrollHelperRef.current, {
      delay: 0.7,
      duration: 0.3,
      y: 60,
      opacity: 0,
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: document.body,
        start: 'top top',
        end: 'bottom bottom',
        scrub: true,
        onUpdate: self => {
          if (self.progress > 0.5) {
            gsap.to(ScrollTooltip.current, {autoAlpha: 0, duration: 0.3});
            gsap.to(ScrollIcon.current, {autoAlpha: 1, rotation: 180, duration: 0.3});
            setIsScrollToTop(true);  // Enable ScrollToTop
          } else if (self.progress > 0.01) {
            gsap.to(ScrollTooltip.current, {autoAlpha: 0, duration: 0.3});
            gsap.to(ScrollIcon.current, {autoAlpha: 0, duration: 0.3});
            setIsScrollToTop(false);  // Disable ScrollToTop
          } else {
            gsap.to(ScrollTooltip.current, {autoAlpha: 1, duration: 0.3});
            gsap.to(ScrollIcon.current, {autoAlpha: 1, rotation: 0, duration: 0.3});
            setIsScrollToTop(false);  // Disable ScrollToTop
          }
        }
      }
    });

    return () => {
      ScrollTrigger.refresh(); // Refresh the ScrollTrigger positions
      tl.kill(); // Optional: kill the timeline
      gsap.set(ScrollTooltip.current, { clearProps: 'all' }); // Clean up gsap applied styles
      gsap.set(ScrollIcon.current, { clearProps: 'all' }); // Clean up gsap applied styles
    };

  }, [location.pathname]);  // Dependency on pathname, changes on route change

  const handleJumpUp = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };


  return (
      <div ref={ScrollHelperRef} id='ScrollHelper' className={'fixed bottom-0 z-40 hidden w-screen pointer-events-none'}>
        <div className={'container mx-auto mb-10'}>
          <div className={'flex items-center justify-end'}>
            <p ref={ScrollTooltipRef} className={'text-dark-gray font-light text-xs mr-4'}>Scroll Down</p>
            <button onClick={handleJumpUp} className={isScrollToTop ? 'pointer-events-auto' : 'pointer-events-none'}
                    aria-label={isScrollToTop ? 'Scroll to top' : 'Scroll down'}>
              <div ref={ScrollIconRef}>
                <FiArrowDownCircle
                    className={`stroke-dark-gray stroke-0.4 text-4xl ${isScrollToTop ? 'hover:stroke-gray-light' : ''}`} />
              </div>
            </button>
          </div>
        </div>
      </div>
  );
}

export default ScrollHelper;